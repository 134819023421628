<template>
  <el-card class='batch-remittance-payee-not-account business-handling my-card' :body-style="cardBody">
    <el-page-header @back="$router.go(-1)" :title="$t('Back')">
      <template slot="content"><span style="font-size:14px">{{$t('remittanceBusiness')}} / {{$t('batchOfRemittance')}}</span></template>
    </el-page-header>
    <div class="form-container">
      <el-divider content-position="left">
        <el-form inline ref="queryForm" :model="queryForm" :rules="rules" style="height:45px;overflow:hidden;">
          <el-form-item :label="$t('remitterInfo')" prop="param" style="margin-bottom:0">
            <el-input size="mini" v-model="queryForm.param" :placeholder="$t('inputPhoneOrAccount')"></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom:0">
            <el-button size="mini" type="primary" @click="onQuery('queryForm')">{{$t('query')+' '+$t('remitterInfo')}}</el-button>
          </el-form-item>
        </el-form>
      </el-divider>

      <el-form v-if="remitterInfo" ref="remitterForm" :model="remitterForm" :rules="rules" label-position="top">
        <el-input type="hidden" size="medium" v-model="remitterInfo.businessCode" readonly></el-input>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item :label="$t('firstName')">
              <el-input size="medium" v-model="remitterInfo.firstName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('lastName')">
              <el-input size="medium" v-model="remitterInfo.lastName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('phone')">
              <el-input size="medium" v-model="remitterInfo.phone" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('idNumber')">
              <el-input size="medium" v-model="remitterInfo.idCard" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('accountAmount')">
              <el-input size="medium" v-model="remitterInfo.accountAmount" readonly>
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('account')">
              <el-input size="medium" v-model="remitterInfo.cardNo" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">{{$t('beneficiaryInfo')}}</el-divider>

        <el-form-item prop="excelName">
          <el-upload class="upload-demo" action="/back/transaction-info/batch-remittance-transfer-import" accept=".xls,.xlsx" :on-remove="handleRemove" :on-success="handleSuccess" :auto-upload="false" :on-change="onChange" :file-list="payeeExcel" :multiple="false" :limit="1" name="attach" list-type="picture" :data="{type:1,businessCode:remitterInfo.businessCode}" ref="uploadExcel">

            <el-button v-if="!isSelectExcel" slot="trigger" size="small" type="primary">{{$t('select')}} EXCEL</el-button>
            <div v-if="!isSelectExcel" slot="tip" class="el-upload__tip">
              {{$t('onlyUploadEXCELFileAndInXLSXOrXLSFormat')}};
              {{$t('No template')}}?
              <a :href="excelTempUrl" :download="$t('remittance-template')">{{$t('click to download')}}</a>
            </div>
          </el-upload>
          <el-input size="medium" type="hidden" v-model="remitterForm.exelUrl"></el-input>
          <el-input size="medium" type="hidden" v-model="remitterForm.excelName"></el-input>
        </el-form-item>
        <el-divider content-position="left">{{$t('remittanceInfo')}}</el-divider>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item :label="$t('remittanceAmount')">
              <el-input size="medium" v-model="remitterForm.amount" disabled>
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item :label="$t('serviceCharge')">
              <el-input size="medium" v-model="remitterForm.serviceCharge" disabled>
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <transaction-tax-display businessCode="22" :transaction-amount="remitterForm.amount"></transaction-tax-display>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('serviceCharge')+$t('payment')" prop="payServiceChargeType">
              <el-select size="medium" v-model="remitterForm.payServiceChargeType">
                <el-option :label="$t('remitterPayment')" :value="0"></el-option>
                <el-option :label="$t('payeePayment')" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('remittance reason')" prop="purpose">
              <el-select size="medium" v-model="remitterForm.purpose">
                <el-option :label="$t('School aid')" :value="$t('School aid')"></el-option>
                <el-option :label="$t('Family assistance')" :value="$t('Family assistance')"></el-option>
                <el-option :label="$t('Building construction')" :value="$t('Building construction')"></el-option>
                <el-option :label="$t('Business settlement')" :value="$t('Business settlement')"></el-option>
                <el-option :label="$t('Service settlement')" :value="$t('Service settlement')"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('messageAuthenticationCode')" prop="smsCode">
              <el-input size="medium" v-model="remitterForm.smsCode">
                <el-button slot="append" @click="getSmsVerification" :disabled="surplusTime>0">
                  <span v-if="!surplusTime">{{$t('Send SMS verification code')}}</span>
                  <span v-else>{{$t("send it again")+`(${surplusTime})`}}</span>
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item :label="$t('remark')">
              <el-input size="medium" type="textarea" v-model="remitterForm.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div v-if="remitterInfo" class="bottom-button">
      <el-button v-if="!printParam" :loading="isLoading" size="medium" type="primary" @click="onsubmitRemittance('remitterForm')">{{$t('submit')}}</el-button>
      <el-button v-else size="medium" @click="printVisible=true">{{$t('printrRceipt')}}</el-button>
    </div>

    <!-- 显示excel内容 -->
    <el-dialog :title="tableExcel.fileName" :visible.sync="uploadVisible" width="80%" :modal-append-to-body="false" :close-on-press-escape="false" :show-close="false" :close-on-click-modal="false" custom-class="excel-table">
      <el-table :data="tableExcel.fileContent" border style="width: 100%" size="small" height="100%">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="name + sname" :label="$t('compellation')" min-width="180">
          <template slot-scope="scope"> {{scope.row.name}} {{scope.row.sname}} </template>
        </el-table-column>
        <el-table-column prop="idCard" :label="$t('idCard')" min-width="180"></el-table-column>
        <el-table-column prop="phone" :label="$t('linkPhone')" min-width="180"></el-table-column>
        <el-table-column prop="amount" :label="$t('amount')" min-width="180"></el-table-column>
        <el-table-column prop="remark" :label="$t('remark')" min-width="180"></el-table-column>
      </el-table>
      <div class="excel-table-btn">
        <el-button size="small" icon="el-icon-refresh" @click="onReselectFile">{{$t('reselect file')}}</el-button>
        <el-button size="small" type="primary" icon="el-icon-upload2" @click="submitUpload" :loading="isUploading">{{$t('upload')}} EXCEL</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('printrRceipt')" :visible.sync="printVisible" width="500px" :modal-append-to-body="false" :destroy-on-close="true" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
      <print-credentials :param="printParam" :isClosePrint.sync="printVisible" />
    </el-dialog>
  </el-card>
</template>

<script>
import customer from "@/api/customer"
import transactionInfo from '@/api/transactionInfo'
import TransactionTaxDisplay from '@/components/TransactionTaxDisplay'
import printCredentials from '@/components/printCredentials'
import XLSX from 'xlsx'
export default {
  components: { printCredentials, TransactionTaxDisplay },
  data () {
    return {
      isLoading: false,
      queryForm: {},

      remitterForm: {},
      remitterInfo: null,
      payeeExcel: [],
      payeeForm: {},
      isSelectExcel: false,
      uploadVisible: false,
      isUploading: false,
      uploadSuccessFileVisible: true,

      printVisible: false,
      printParam: null,

      tableExcel: { fileContent: [], fileName: "" },

      surplusTime: 0
    }
  },
  computed: {
    excelTempUrl () { return './template/remittance-template.xlsx' },
    cascader () { return this.$store.state.format.cascader },
    cardBody () { return this.$store.state.style.cardBody },
    countryTree () { return this.$store.state.globalParameter.countryTree; },
    rules () {
      return {
        param: [{ required: true, trigger: 'blur' }],
        payServiceChargeType: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        smsCode: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        excelName: [{ required: true, message: this.$t('The transfer template needs to be uploaded'), trigger: 'blur' }],
        purpose: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    }
  },
  methods: {
    onQuery (refName) {
      let _this = this;
      this.$refs[refName].validate(valid => {
        if (valid) {
          let p = {};
          if (_this[refName].param.length === 15) p.cardNo = _this[refName].param;
          else p.phone = _this[refName].param;

          customer.getByAccountOrPhone({
            param: p,
            success: res => {
              if (res.code != 200) {
                _this.$message.warning(_this.$t(res.msg));
                _this.remitterInfo = null;
                this.$refs.uploadExcel.clearFiles();
                this.isSelectExcel = false;
                return;
              }
              _this.remitterInfo = {
                businessCode: _this.$route.params.code,
                firstName: res.data.name,
                lastName: res.data.surname,
                phone: res.data.phoneNo,
                idCard: res.data.idCard,
                accountAmount: res.data.accountAmount,
                cardNo: res.data.cardNo,
                cardId: res.data.cardId
              };
            }
          })
        }
        else return false;
      })
    },
    /**移除文件 */
    handleRemove (file, fileList) {
      this.uploadVisible = false;
      this.isSelectExcel = false;
      this.$set(this.remitterForm, 'amount', undefined)
      this.$set(this.remitterForm, 'serviceCharge', undefined)
      console.log('移除文件', file, fileList)
    },
    /**上传成功 */
    handleSuccess (uploadRes) {
      if (uploadRes.code == 200) {
        this.$message.success(this.$t('successfully upload'));
        this.remitterForm.excelName = uploadRes.data;
        this.isUploading = false;
        let _this = this;
        transactionInfo.totalBatchImport({
          param: { type: 1, status: 1, excelName: uploadRes.data },
          success: res2 => {
            _this.$set(_this.remitterForm, 'amount', res2.data.totalAmount)
            _this.$set(_this.remitterForm, 'serviceCharge', res2.data.totalServiceCharge)
          }
        })
      }
      else {
        this.$message.warning(this.$t(uploadRes.msg));
        this.$refs.uploadExcel.clearFiles();
      }
    },
    /**上传文件 */
    submitUpload () {
      this.isUploading = true;
      this.$refs.uploadExcel.submit();
    },
    /**显示上传按钮 */
    onChange (file) {
      //上传成功后不再显示table
      if (this.uploadVisible) {
        this.isUploading = false;
        this.uploadVisible = false;
        return;
      }

      this.isSelectExcel = true;
      let files = { 0: file.raw }
      this.readExcel1(files);
    },
    /**显示EXCEL内容 */
    readExcel1 (files) {
      if (files.length <= 0) {//如果没有文件名
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$message.warning(this.$t('onlyUploadEXCELFileAndInXLSXOrXLSFormat'));
        return false;
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const wsname = workbook.SheetNames[0];//取第一张表
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);//生成json表格内容
          this.uploadVisible = true;
          this.tableExcel = { fileContent: ws, fileName: files['0'].name };
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },

    getSmsVerification () {
      let _this = this;
      transactionInfo.sendVerificationCode({
        param: { phone: _this.remitterInfo.phone }, success: function (res) {
          if (res.code != 200) {
            _this.$message.warning(_this.$t(res.msg))
            return
          }

          _this.surplusTime = 60;
          let interval = setInterval(() => {
            _this.surplusTime -= 1;
            if (_this.surplusTime === 0)
              clearInterval(interval);
          }, 1000)

          _this.$message.success(_this.$t("SMS verification code sent successfully") + "，" + _this.$t("Verification code Receiving number") + _this.remitterInfo.phone);
        }
      })
    },

    /**提交汇款信息 */
    onsubmitRemittance (refName) {
      let _this = this;
      _this.$refs[refName].validate(valid => {
        if (!valid) return false;
        _this.isLoading = true;
        let t = { ...this[refName], ...this.remitterInfo };
        let params = {
          businessCode: t.businessCode,
          remittorCardId: t.cardId,
          remittorCardNo: t.cardNo,
          payServiceChargeType: t.payServiceChargeType,
          excelName: t.excelName,
          remark: t.remark,
          smsCode: t.smsCode,
          purpose: t.purpose
        }
        transactionInfo.batchAccountRemittance({
          param: params,
          success: function (res) {
            if (res.code == 200) {
              _this.$message.success('operateSuccessfully');
              _this.$set(_this, "printParam", res.data)
              _this.$set(_this, "printVisible", true)
            }
            else
              _this.$message.warning(_this.$t(res.msg))
            _this.isLoading = false;
          }
        })
      })
    },
    /**重新选择按钮事件 */
    onReselectFile () {
      this.uploadVisible = false;
      this.isUploading = false;
      this.isSelectExcel = false;
      this.$refs.uploadExcel.clearFiles();
    }
  },
}
</script>

<style lang="less" scoped>
.batch-remittance-payee-not-account.business-handling {
  height: 100%;
  width: 100%;
  .el-card__body {
    position: relative;
    .form-container {
      position: absolute;
      top: 60px;
      bottom: 80px;
      left: 20px;
      right: 0;
      padding-right: 20px;
      overflow: auto;
    }
  }
  .el-form {
    height: auto;
  }
  .upload-demo {
    overflow: hidden;
    width: 800px;
    .el-upload__tip {
      display: inline-block;
      margin-left: 20px;
      margin-top: 2px;
      a {
        font-weight: bold;
        color: blue;
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="less">
.batch-remittance-payee-not-account.el-card
  .el-card__body
  .el-upload-list--picture {
  width: 500px;
  li {
    height: 93px !important;
    .el-upload-list__item-name {
      margin: 10px 0 !important;
      i {
        position: unset !important;
      }
    }
  }
}
.batch-remittance-payee-not-account .upload-my .el-form-item__error {
  top: 42px !important;
}
.excel-table {
  height: calc(100% - 30vh);
  .el-dialog__body {
    height: calc(100% - 150px);
    padding: 10px 20px;
  }
  .excel-table-btn {
    margin-top: 20px;
    text-align: right;
  }
}
</style>